$(function () {




    //
    // mobile nav functions
    //

    var $navTrigger = $(".nav-trigger");
    var $mobileNav = $("nav.mobile");

    $navTrigger.on("click", function () {
        var buttonText = $navTrigger.text();
        $(this).text(buttonText == "Menu" ? "Close" : "Menu");

        $mobileNav.toggleClass("open");
    });

    var $expandable = $(".expandable > a");

    $expandable.on("click", function (e) {
        var screenWidth = $(window).width();

        if (screenWidth > 1123) {
            return;
        } else {
            e.preventDefault();
            $(this)
                .closest(".expandable")
                .find("ul")
                .toggleClass("open");
        }
    });

    $(window).on("resize", function () {
        var screenWidth = $(window).width();

        if (screenWidth > 1123) {
            $navTrigger.text("Menu");
            $mobileNav.removeClass("open");
        }
    });

    checkPosition();

    $(window).scroll(function () {
        checkPosition();
    });

    function checkPosition() {
        var height = $(window).height();
        var top = $(window).scrollTop();
        var target = Number(height / top);

        if (target <= 2 && !($('.js-filters').hasClass('-open'))) {
            $(".back_to_top").addClass("visible");
        } else {
            $(".back_to_top").removeClass("visible");
        }
    }

    $(".back_to_top").click(function () {
        $("html,body").animate({
            scrollTop: 0
        }, 200);
        $("li.home-link a").focus();
    });

    $(".back_to_top").keypress(function (e) {
        $("html,body").animate({
            scrollTop: 0
        }, 200);
        $("li.home-link a").focus();
    });
    function placePercentage() {
        $(".bar .progress").each(function () {
            var barWidth = parseInt($(this).css("width"));

            if (barWidth <= 70) {
                $(this)
                    .find("span")
                    .addClass("outside");
            } else {
                $(this)
                    .find("span")
                    .removeClass("outside");
            }
        });
    }

    $(window).resize(function () {
        setTimeout(function () {
            placePercentage();
        }, 500);
    });




    var select2Init = function (i, value) {

        var $selection = $(this);

        if ($selection.data("allowclear")) {
            $selection.select2({
                placeholder: $selection.attr('placeholder'),
                allowClear: true
            }).on("select2:unselecting", function(e) {
                $(this).data('state', 'unselected');
            }).on("select2:open", function(e) {
                if ($(this).data('state') === 'unselected') {
                    $(this).removeData('state');

                    $(".select2-search__field").removeAttr("role");
                    $(".select2-search__field").attr("aria-label", "textbox");
                    $(".select2-search__field").attr("type", "text");

                    var self = $(this);
                    setTimeout(function() {
                        self.select2('close');
                    }, 1);
                }
            });
        } else {
            $selection.select2({
                placeholder: $selection.attr('placeholder'),
            });
        }

    };

    var $select2 = $("select")
            .not(".plain")
            .not(".flatpickr-monthDropdown-months")
            .each(select2Init);

    $(".shadow").hide();
    $(".shadow").each(function (i, value) {
        var $shadow = $(this);
        var $toggle = $shadow
            .parents("form")
            .find('[name="' + $shadow.data("shadow") + '"]');

        if ($toggle.is(":checked")) {
            $shadow.show();
        }

        $toggle.on("change", function () {
            if ($(this).is(":checked")) {
                $shadow.show();
            } else {
                $shadow.hide();
            }
        });
    });

    $(".sortable").each(function () {
        var $sortable = $(this);
        var max_depth = $sortable.data("max-depth");

        if (!max_depth) {
            var max_depth = 1;
        }

        $(this)
            .nestable({
                rootClass: "sortable",
                listClass: "collection",
                itemClass: "entity",
                handleClass: "handle",
                emptyClass: "empty",
                dragClass: "dragel",
                placeClass: "placeholder",
                maxDepth: max_depth,
                threshold: 20,
                group: 1
            })
            .on("change", function () {
                var $stack = $(this);

                $stack.find(".collection").each(function (i, list) {
                    var $list = $(list);
                    var $parent = $list.parent(".entity");
                    var $entities = $list.children(".entity");

                    if ($parent.length) {
                        $entities
                            .find("input[data-map=sortable-parent]")
                            .val($parent.data("id"));
                    } else {
                        $entities
                            .find("input[data-map=sortable-parent]")
                            .val("");
                    }

                    $entities.each(function (i, entity) {
                        $(entity)
                            .find("input[data-map=sortable-position]")
                            .val(i);
                    });
                });
            });
    });

    $('.js-exposeFilters').click(function() {
        $('.js-occupationFilters').toggleClass('-revealed');
    });

    $("a.favorite").click(function (e) {
        $anchor = $(this);
        var thisPath = $anchor.attr("href");

        $.get(this.href, {}, function () {
            if ($('a[href$="' + thisPath + '"').hasClass("selected")) {
                $('a[href$="' + thisPath + '"').removeClass("selected");
                $('a[href$="' + thisPath + '"').text("Add to Favorites");
            } else {
                $('a[href$="' + thisPath + '"').addClass("selected");
                $('a[href$="' + thisPath + '"').text("Remove from Favorites");
            }
        });

        e.preventDefault();
        return false;
    });

    $(".result label").each(function () {
        $(this).click(function (e) {
            e.preventDefault();

            $select = $(this);

            if ($select.hasClass("selected")) {
                $(this).removeClass("selected");
                $(this)
                    .find("span")
                    .text("Compare");
                $(this)
                    .find("input")
                    .prop("checked", false);
                $(this)
                    .find("input")
                    .change();
            } else {
                $(this).addClass("selected");
                $(this)
                    .find("span")
                    .text("Selected");
                $(this)
                    .find("input")
                    .prop("checked", true);
                $(this)
                    .find("input")
                    .change();
            }
        });
    });

    $(".check").change(function () {
        var selected = $(".check:checked").length;

        if (selected > 1) {
            $("button#compare").removeAttr("disabled");
        } else {
            $("button#compare").attr("disabled", "disabled");
        }

        if (selected) {
            $("button#remove").removeAttr("disabled");
        } else {
            $("button#remove").attr("disabled", "disabled");
        }
    });


    //
    // Google Event Tracking
    //
    var mbbRole = $('meta[name="mbb:role"]');
    var hasRole = mbbRole.attr("content") !== 'none' && mbbRole.length;

    if (!hasRole || !mbbRole.length) {
        sessionStorage.removeItem("logIn");
    }

    if (typeof window.ga !== "undefined" && !sessionStorage.getItem("logIn")) {
        var mbbSite = $('meta[name="mbb:site"]');
        var mbbNetwork = $('meta[name="mbb:network"]');
        //
        // Send GA Analytic Events
        //

        if (hasRole) {

            window.ga("send", {
                hitType: "event",
                eventCategory: "log-in",
                eventAction: "log-in",
                eventLabel: "role: " +
                    mbbRole.attr("content") +
                    ", " +
                    "site: " +
                    mbbSite.attr("content") +
                    ", " +
                    "network: " +
                    mbbNetwork.attr("content")
            });

            sessionStorage.setItem("logIn", 1);
        }
    }

    // Hide videos for print
    $("iframe").each(function () {
        var $this = $(this);
        var thisUrl = $this.attr("src") || "nosrc";

        if (
            thisUrl.indexOf("youtube") != -1 ||
            thisUrl.indexOf("vimeo") != -1 ||
            thisUrl.indexOf("wistia") != -1
        ) {
            $this.addClass("no-print");
        }
    });

    $('[type="number"]').on('propertychange input', function (e) {
        this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    });

    $("[data-filter]").each(function () {
        var $filter  = $(this);
        var $list    = $($filter.data('filter'));
        var target  = $filter.data('filter-target');



        var search = function() {
            var query = $filter.val().toLowerCase();

            $list.find('> *').each(function() {
                var $item   = $(this);
                var content = $item.find(target).text().toLowerCase();

                if (query.length && content.includes(query)) {
                    $item.show();
                } else {
                    $item.hide();
                }
            });

            if (query.length && !$list.find('> *:visible').length) {
                $filter.find('+ .error').show();
            } else {
                $filter.find('+ .error').hide();
            }
        };

        $filter.on('keyup', search);
        search();
    });

    var $quickSelectors = $('[data-qs]');

    $quickSelectors.on('click', function($e) {
        var $select  = $($(this).data('qs-target'));
        var $options = $select.find($(this).data('qs'));

        $select.find('option').removeAttr('selected');
        $options.attr('selected', 'selected');

        if ($select.is('.select2-hidden-accessible')) {
            $select.val($options.map(function() {
                return $(this).val();
            })).trigger('change');
        }

        $e.stopPropagation();
        $e.preventDefault();

        return false;
    });


    var $filterOpen = $('.js-openFilters');
    var $filterClose = $('.js-closeFilters');
    var $filterContainer = $('.js-filters');

    $filterOpen.on('click', function() {
        $filterContainer.toggleClass('-open');
    });

    $filterClose.on('click', function() {
        $filterContainer.toggleClass('-open');
    });

    var $concernedSelects = $('[data-concern]');
    var $concernOptions = $('.js-concernOption');

    var combFilterOptions = function () {
        $concernOptions.each(function(i, value) {
            $(this).prop("disabled", false );
        });

        $select2.each(function(i, value) {
            $(this).select2('destroy');
        });

        $concernedSelects.each(function (i, value) {
            var $this           = $(this);
            var selectedValue   = $this.val();
            var concernField    = $this.data('concern');
            var $relatedOptions = $('[data-' + concernField + ']');

            $relatedOptions.each(function (i, value) {
                optionValue = $(this).data(concernField);
                if (optionValue && selectedValue) {
                    if (Array.isArray(selectedValue) && selectedValue.indexOf(optionValue.toString()) === -1) {
                        $(this).prop("disabled", true);
                    } else if (!Array.isArray(selectedValue) && selectedValue && optionValue != selectedValue) {
                        if ($(this).prop('selected')) {
                            $(this).removeProp('selected');
                        }

                        $(this).prop("disabled", true);
                    }
                }
            });
        });

        $select2.each(select2Init);
    }

    $concernedSelects.change(combFilterOptions);
    combFilterOptions();

    if ($('.js-careerApprovalSelect').length) {
        var toggleDateUi = function() {
            var isApproved = $('.js-careerApprovalSelect').val()[0] * 1;

            if (isApproved) {
                $('.js-careerPlanRange .js-dateUi').prop('disabled', false);
                $('.js-careerPlanRange .js-startDate').prop('disabled', false);
                $('.js-careerPlanRange .js-endDate').prop('disabled', false);
            } else {
                $('.js-careerPlanRange .js-dateUi').prop('disabled', true);
                $('.js-careerPlanRange .js-startDate').prop('disabled', true);
                $('.js-careerPlanRange .js-endDate').prop('disabled', true);
            }
        }

        toggleDateUi();
        $('#approval').change(toggleDateUi);
    }

    (function(body) {

        var usingMouse;

        var preFocus = function(event) {
            usingMouse = (event.type === 'mousedown');
        };

        var addFocus = function(event) {
            if (usingMouse)
                event.target.classList.add('focus--mouse');
        };

        var removeFocus = function(event) {
            event.target.classList.remove('focus--mouse');
        };

        var bindEvents = function() {
            body.addEventListener('keydown', preFocus);
            body.addEventListener('mousedown', preFocus);
            body.addEventListener('focusin', addFocus);
            body.addEventListener('focusout', removeFocus);
        };

        bindEvents();

    })(document.body);
});




